import axios from 'axios'
import { generatePath } from "react-router-dom"
import { logout, removeCurrentUser } from "./AuthHelper"
import { objectToArray } from "./CommonHelper"

const __API_BASE_URL = 'http://127.0.0.1:3001/api/'

const getResponse = (response) => {
    //console.log({response:response?.data?.status});
    if ( response?.data?.status === 401 || response.status === 401 || response.status === 403 ) {
        window.location.href = '/login';
        throw new Error("Unauthorized - Redirecting to login"); 
    }
    if (response.status !== 200) {
        return false
    }
    if (!response.data.status || !response.data.data) {
        return false
    }
    return response.data.data
}

axios.defaults.baseURL = '/api';
axios.defaults.withCredentials = true

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
        //Token expired or logged out. Remove user from local storage
        removeCurrentUser()
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

class ApiHelper {
    static login = async ({ username, password }) => {
        const response = await axios.post('/users/login', {
            email: username,
            password
        })

        if (response.status !== 200) {
            return false
        }

        if (!response.data.status || !response.data.data.user) {
            return false
        }

        return response.data.data.user
    }

    static logout = async () => {
        const response = await axios.get('/users/logout')

        if (response.status !== 200) {
            return false
        }

        if (!response.data.status || !response.data.data.message) {
            return false
        }

        return response.data.data
    }

    static getSupplierProducts = async ({ supplier, query = '', category = null, status = null, page = 1, size = 20, sort = null }) => {
        let params = { page, size }
        if (category) {
            params.category = category
        }
        if (query) {
            params.query = query
        }

        if (status) {
            params.status = status
        }

        if(sort){
            params.sort = sort
        }

        const response = await axios.get(`supplier/${supplier}/products`, {
            params
        })
        return (getResponse(response) || [])
    }

    static getSupplierCategories = async (supplier) => {
        const response = await axios.get(`supplier/${supplier}/categories`)
        return (getResponse(response) || [])
    }

    static getProductDetails = async (supplier, code) => {
        if (!code) {
            return {}
        }
        const response = await axios.get(`supplier/${supplier}/product/${code}`)
        return (getResponse(response) || {})
    }

    static getProductsCounting = async ({ supplier, query = '', category = null, status = null }) => {
        let params = { }
        if (category) {
            params.category = category
        }
        if (query) {
            params.query = query
        }

        if (status) {
            params.status = status
        }
        const response = await axios.get(`supplier/${supplier}/productsCounting`, {
            params
        })
        return (getResponse(response) || {})
    }

    static getSupplierSpecs = async (supplier, specification) => {
        const response = await axios.get(`supplier/${supplier}/${specification}`)
        return (getResponse(response) || [])
    }

    static getCMSSpecs = async (specification) => {
        let url = new URL(`${__API_BASE_URL}cms/specification/${specification}`)
        const response = await axios.get(`cms/specification/${specification}`)
        return (getResponse(response) || [])
    }

    static addCMSSpecValue = async (specification, specValueTexts) => {
        const response = await axios.post(`cms/specification/${specification}/value`, specValueTexts)
        return (getResponse(response) || [])
    }

    static getSpecificationMappings = async (supplier, specification) => {
        let url = new URL(`${__API_BASE_URL}mappings/${supplier}/${specification}`)
        const response = await axios.get(`mappings/${supplier}/${specification}`)
        return (getResponse(response) || [])
    }

    static saveSpecificationMappings = async (supplier, specification, mappings) => {
        const response = await axios.post(`mappings/${supplier}/${specification}`, mappings)
        return (getResponse(response))
    }

    static getCMSGeneralLedgers = async () => {
        let url = new URL(`${__API_BASE_URL}cms/generalledgers`)
        const response = await axios.get('cms/generalledgers')
        return objectToArray(getResponse(response), 'value', 'label')
    }

    static getCMSCategories = async () => {
        const response = await axios.get('cms/categories')
        return (getResponse(response) || {})
    }

    static getProductMapping = async (supplier, code) => {
        const response = await axios.get(`mappings/${supplier}/product/${code}`)
        return (getResponse(response) || {})
    }

    static saveProductMapping = async (supplier, code, data) => {
        const response = await axios.post(`mappings/${supplier}/product/${code}`, data)
        return getResponse(response)
    }

    static generateProductPreview = async (supplier, code, data) => {
        const response = await axios.post(`mappings/${supplier}/product/${code}`, data)
        if (!getResponse(response)) {
            return false
        }
        const response_data = getResponse(response);
        if( !response_data.status ){
            return response_data;
        }
        const mappingResponse = await axios.get(`mappings/${supplier}/product/preview/${code}`)
        return getResponse(mappingResponse)
    }

    static forceProductPush = async (supplier, code, data) => {
        const response = await axios.post(`mappings/${supplier}/product/${code}`, data)
        if (!getResponse(response)) {
            return false
        }
        const response_data = getResponse(response);
        if( !response_data.status ){
            return response_data;
        }
        const mappingResponse = await axios.get(`mappings/${supplier}/product/push/${code}`)
        return getResponse(mappingResponse)
    }

    static getSuppliers = async (supplier, code) => {
        const response = await axios.get('supplier/list')
        const data = (getResponse(response) || {})
        return data?.suppliers

    }

    static getPriceSettings = async (supplier) => {
        const response = await axios.get(`settings/prices/${supplier}`)
        return (getResponse(response) || {})
    }

    static getSupplierCmsCategories = async (supplier) => {
        const response = await axios.get(generatePath('cms/:supplier/categories', {
            supplier
        }))
        return (getResponse(response) || {})
    }

    static getCmsCategoryMargin = async (supplier, categoryId) => {
        const response = await axios.get(generatePath('mappings/:supplier/category/:categoryId/margin', {
            supplier,
            categoryId,
        }))
        return (getResponse(response) || {})
    }

    static updateCmsCategoryMargin = async (supplier, categoryId, margin, productMargins = []) => {
        const response = await axios.post(generatePath('mappings/:supplier/category/:categoryId/margin', {
            supplier,
            categoryId,
        }), {
            margin: parseFloat(margin),
            productMargins: productMargins
        })
        console.log(getResponse(response))
        return (getResponse(response) || {})
    }

    static getSupplierProductsByCategory = async (supplier, categoryId) => {
        const response = await axios.get(generatePath('cms/:supplier/category/:categoryId/products', {
            supplier,
            categoryId,
        }))
        return (getResponse(response) || {})
    }

    static getCmsBrands = async () => {
        const response = await axios.get(generatePath('cms/brands'))
        return (getResponse(response) || {})
    }

    // static updateCmsProductMargins = async (supplier, productMargins) => {
    //     const response = await axios.post(generatePath('mappings/:supplier/products/margin', {
    //         supplier,
    //     }), {
    //         productMargins: Object.entries(productMargins).map(([productId, margin]) => ({
    //             productId,
    //             margin
    //         }))
    //     })
    //     return (getResponse(response) || {})
    // }

    static removeMapping = async (supplier, type, data) => {
        const response = await axios.post(`/mappings/${supplier}/${type}/remove`, data)
        return getResponse(response)
    }
}
export default ApiHelper;